import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Heading from "~components/atoms/heading/heading";
import SVGIcon from "~components/utils/svg_icon/svg_icon";
import SearchJobsResultFlagList from "../search_jobs_result_flag_list/search_jobs_result_flag_list";
import getDepartmentIcon from "./helper_functions/get_department_icon";
import {
  headingStyle,
  innerWrapperStyle,
  wrapperStyle,
} from "./search_jobs_result_accordion_header.module.scss";

const HEADING_MARGIN = { marginBottom: "none" };

export default function SearchJobsResultAccordionHeader({
  departmentTitle,
  isActive,
  onClick,
  jobList,
}) {
  const titleWithJobCount = `${departmentTitle} (${jobList.length})`;

  const departmentIcon = useMemo(() => {
    return getDepartmentIcon({ departmentTitle });
  }, [departmentTitle]);

  return (
    <button type="button" onClick={onClick} className={wrapperStyle}>
      <SVGIcon
        width={32}
        height={32}
        icon={departmentIcon}
        isActive={isActive}
      />
      <div className={headingStyle}>
        <div className={innerWrapperStyle}>
          <Heading noWrap seoLevel="2" visualLevel="6" margin={HEADING_MARGIN}>
            {titleWithJobCount}
          </Heading>

          <SearchJobsResultFlagList jobList={jobList} />
        </div>

        <SVGIcon
          width={24}
          height={24}
          icon="icon_chevron_down"
          isActive={isActive}
        />
      </div>
    </button>
  );
}

SearchJobsResultAccordionHeader.defaultProps = {
  departmentTitle: null,
  jobList: null,
  isActive: false,
  onClick: () => {},
};

SearchJobsResultAccordionHeader.propTypes = {
  departmentTitle: PropTypes.string,
  jobList: PropTypes.arrayOf(PropTypes.shape({})),
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};
