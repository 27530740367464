import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SVGFlagIcon from "~components/atoms/svg_flag_icon/svg_flag_icon";
import countryToIsoCode from "~utils/country_utils/country_to_iso_code";
import { LINK } from "../../../../../prop_types";
import { locationWrapperStyle } from "./search_jobs_result_link_location.module.scss";

const SearchJobsResultLinkLocation = ({ job }) => {
  const countryCode = useMemo(() => {
    return countryToIsoCode(job.office);
  }, [job.office]);

  if (job.location && job.office) {
    return (
      <div className={locationWrapperStyle}>
        <SVGFlagIcon
          small
          marginRight
          countryCode={countryCode}
          altText={job.office}
        />
        {job.location}
      </div>
    );
  }
  return null;
};

SearchJobsResultLinkLocation.propTypes = {
  job: PropTypes.shape({
    link: LINK,
    title: PropTypes.string,
    office: PropTypes.string,
    department: PropTypes.string,
    teamName: PropTypes.string,
    location: PropTypes.string,
  }).isRequired,
};

export default SearchJobsResultLinkLocation;
