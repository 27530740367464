import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Highlight } from "react-instantsearch-dom";
import { Interactive } from "react-interactive";
import Tag from "~components/molecules/tags/tag";
import LinkWrapper from "~components/utils/link_wrapper/link_wrapper";
import SVGIcon from "~components/utils/svg_icon/svg_icon";
import useHandleInteractiveStateChange from "~hooks/use_handle_interactive_state_change/use_handle_interactive_state_change";
import { LINK } from "../../../../../prop_types";
import SearchJobsResultLinkLocation from "../search_jobs_result_link_location/search_jobs_result_link_location";
import {
  iconStyle,
  isActiveStyle,
  jobLinkInnerStyle,
  jobLinkOuterStyle,
  jobTitleStyle,
  teamStyle,
} from "./search_jobs_result_link.module.scss";

const SearchJobsResultLink = ({ job }) => {
  const [isActive, handleInteractiveStateChange] =
    useHandleInteractiveStateChange();

  const titleClassNames = classNames(jobTitleStyle, {
    [isActiveStyle]: isActive,
  });
  const iconClassNames = classNames(iconStyle, {
    [isActiveStyle]: isActive,
  });

  return (
    <Interactive
      as={LinkWrapper}
      link={job.link}
      onStateChange={handleInteractiveStateChange}
      className={jobLinkOuterStyle}
    >
      <div className={jobLinkInnerStyle}>
        <div className={titleClassNames}>
          <Highlight attribute="title" hit={job} tagName="mark" />
        </div>
        <div className={teamStyle}>
          {isActive}
          <Tag title={job.teamName} />
        </div>
        <SearchJobsResultLinkLocation job={job} />
      </div>
      <div className={iconClassNames}>
        <SVGIcon
          width={24}
          height={24}
          icon="icon_chevron_right"
          isActive={isActive}
        />
      </div>
    </Interactive>
  );
};

SearchJobsResultLink.propTypes = {
  job: PropTypes.shape({
    link: LINK,
    title: PropTypes.string,
    office: PropTypes.string,
    department: PropTypes.string,
    teamName: PropTypes.string,
    location: PropTypes.string,
  }).isRequired,
};

export default SearchJobsResultLink;
