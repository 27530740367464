import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SVGFlagIcon from "~components/atoms/svg_flag_icon/svg_flag_icon";
import countryToIsoCode from "~utils/country_utils/country_to_iso_code";

function SearchJobsResultFlagIcon({ office }) {
  const countryCode = useMemo(() => {
    return countryToIsoCode(office);
  }, [office]);
  return (
    <SVGFlagIcon countryCode={countryCode} altText={office} key={office} />
  );
}

SearchJobsResultFlagIcon.propTypes = {
  office: PropTypes.string,
};

SearchJobsResultFlagIcon.defaultProps = {
  office: null,
};

export default SearchJobsResultFlagIcon;
