import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import SearchJobsFields from "~components/organisms/search/search_jobs/search_jobs_fields/search_jobs_fields";
import { searchInnerStyle } from "./job_list_hero.module.scss";

const HEADING_MARGIN = { marginBottom: "lg" };

const JobListHero = ({ roleCount, searchState, setRoleCount, title }) => {
  return (
    <Contain isTextCentered>
      {roleCount > 0 && (
        <Heading visualLevel={7} seoLevel={4} isUppercase color="gray">
          {roleCount === 1 && `${roleCount} role`}
          {roleCount > 1 && `${roleCount} roles`}
        </Heading>
      )}
      <Heading margin={HEADING_MARGIN} visualLevel={1} seoLevel={1}>
        {title}
      </Heading>
      <div className={searchInnerStyle}>
        <SearchJobsFields
          searchState={searchState}
          roleCountCallback={setRoleCount}
        />
      </div>
    </Contain>
  );
};

JobListHero.propTypes = {
  roleCount: PropTypes.number,
  searchState: PropTypes.shape({}),
  setRoleCount: PropTypes.func.isRequired,
  title: PropTypes.string,
};

JobListHero.defaultProps = {
  roleCount: 0,
  searchState: null,
  title: "Join the team",
};

export default JobListHero;
