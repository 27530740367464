export default function sortJobsByTeamName({ jobList }) {
  return jobList.sort((a, b) => {
    if (a.teamName < b.teamName) {
      return -1;
    }
    if (a.teamName > b.teamName) {
      return 1;
    }
    return 0;
  });
}
