import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connectHits } from "react-instantsearch-dom";
import SearchJobsNoMatch from "../search_jobs_no_match/search_jobs_no_match";
import SearchJobsResultListWrapper from "../search_jobs_result_list_wrapper/search_jobs_result_list_wrapper";
import organizeJobsIntoDepartments from "./helper_functions/organize_jobs_into_departments";

const JobSearchHits = ({ roleCountCallback, hits, clearSearchState }) => {
  useEffect(() => {
    roleCountCallback(hits.length);
  }, [hits, roleCountCallback]);

  const departmentsWithJobsList = useMemo(() => {
    return organizeJobsIntoDepartments(hits);
  }, [hits]);

  if (Object.keys(departmentsWithJobsList).length > 0) {
    return <SearchJobsResultListWrapper list={departmentsWithJobsList} />;
  }

  return <SearchJobsNoMatch clearSearchState={clearSearchState} />;
};

JobSearchHits.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  roleCountCallback: PropTypes.func.isRequired,
  clearSearchState: PropTypes.func.isRequired,
};

export default connectHits(JobSearchHits);
