const DEPARTMENT_ICON_MAP = {
  "Client Ops": "icon_support",
  Commercial: "icon_commercial",
  Design: "icon_design",
  Engineering: "icon_engineering",
  Finance: "icon_finance",
  "Legal & Compliance": "icon_legal_compliance",
  Marketing: "icon_marketing",
  People: "icon_people",
  Product: "icon_product",
  Sales: "icon_commercial",
  "Strategy & Operations": "icon_strategy",
  Talent: "icon_talent",
  // fallback to careers "ladder" icon
  default: "icon_careers",
};
export default function getDepartmentIcon({ departmentTitle }) {
  return DEPARTMENT_ICON_MAP[departmentTitle] || DEPARTMENT_ICON_MAP.default;
}
