/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import Button from "~components/molecules/button/button/button";

const NO_MATCH_HEADING = "We couldn't find a match for that.";
const NO_MATCH_TEXT =
  "Maybe try changing your filters or search terms. You could also go back and see all open jobs.";
const NO_MATCH_BUTTON_TEXT = "Clear filters";

const SearchJobsNoMatch = ({ clearSearchState }) => {
  return (
    <Contain>
      <Heading visualLevel={3}>{NO_MATCH_HEADING}</Heading>
      <p>{NO_MATCH_TEXT}</p>
      <Button
        text={NO_MATCH_BUTTON_TEXT}
        isUppercase
        onClick={clearSearchState}
        color="yellow"
      />
    </Contain>
  );
};

SearchJobsNoMatch.propTypes = {
  clearSearchState: PropTypes.func.isRequired,
};

export default SearchJobsNoMatch;
