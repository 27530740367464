import React from "react";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import * as transitionScaleFadeAppear from "../../../../../styles/global_scss_modules/global_css_transition_styles/transition_scale_fade_appear.module.scss";
import SearchJobsResultAccordionWrapper from "../search_jobs_result_accordion_wrapper/search_jobs_result_accordion_wrapper";
import { departmentListStyle } from "./search_jobs_result_list_wrapper.module.scss";

const classNames = { ...transitionScaleFadeAppear };

const TRANSITION_TIMEOUT = {
  enter: 200,
  exit: 200,
};
function SearchJobsResultListWrapper({ list }) {
  return (
    <TransitionGroup component="ul" className={departmentListStyle}>
      {Object.keys(list).map((key) => {
        return (
          <CSSTransition
            key={key}
            timeout={TRANSITION_TIMEOUT}
            classNames={classNames}
            appear
            unmountOnExit
          >
            <SearchJobsResultAccordionWrapper
              key={key}
              jobList={list[key]}
              title={key}
            />
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
}

SearchJobsResultListWrapper.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      department: PropTypes.string,
      jobs: PropTypes.shape({}),
      offices: PropTypes.shape(PropTypes.string),
    })
  ),
};

SearchJobsResultListWrapper.defaultProps = {
  list: [],
};

export default SearchJobsResultListWrapper;
