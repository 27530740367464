import React, { useMemo } from "react";
import PropTypes from "prop-types";
import getDistinctOffices from "../search_jobs_result_accordion_wrapper/helper_functions/get_distinct_offices";
import { flagListStyle } from "./search_jobs_result_flag_list.module.scss";
import SearchJobsResultFlagIcon from "./sub_components/search_jobs_result_flag_icon/search_jobs_result_flag_icon";

function SearchJobsResultFlagList({ jobList }) {
  const offices = useMemo(() => {
    return getDistinctOffices(jobList);
  }, [jobList]);

  return (
    <div className={flagListStyle}>
      {offices &&
        offices.length > 0 &&
        offices.map((office) => {
          return <SearchJobsResultFlagIcon key={office} office={office} />;
        })}
    </div>
  );
}

SearchJobsResultFlagList.propTypes = {
  jobList: PropTypes.arrayOf(PropTypes.shape({})),
};

SearchJobsResultFlagList.defaultProps = {
  jobList: null,
};

export default SearchJobsResultFlagList;
