import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Card from "~components/atoms/card/card";
import useAccordionTransitions from "~hooks/use_accordion_transitions/use_accordion_transitions";
import SearchJobsResultAccordionHeader from "../search_jobs_result_accordion_header/search_jobs_result_accordion_header";
import SearchJobsResultLink from "../search_jobs_result_link/search_jobs_result_link";
import sortJobsByTeamName from "./helper_functions/sort_jobs_by_team_name";
import {
  accordionItemStyle,
  contentStyle,
  isActiveStyle,
  jobListStyle,
  wrapperStyle,
} from "./search_jobs_result_accordion_wrapper.module.scss";

export default function SearchJobsResultAccordionWrapper({ title, jobList }) {
  const [isActive, setIsActive] = useState(false);
  const accordionRef = useAccordionTransitions(isActive);

  const handleButtonClick = useCallback(() => {
    setIsActive((currentState) => {
      return !currentState;
    });
  }, []);

  const accordionClassNames = classNames(accordionItemStyle, {
    [isActiveStyle]: isActive,
  });

  const jobsSortedByTeamName = useMemo(() => {
    return sortJobsByTeamName({ jobList });
  }, [jobList]);

  return (
    <div className={wrapperStyle}>
      <Card hoverState isFullWidth>
        <div className={accordionClassNames}>
          {/* ————— ACCORDION HEADER — ACTS AS OPEN/CLOSE BUTTON ————— */}
          <SearchJobsResultAccordionHeader
            {...{
              departmentTitle: title,
              jobList,
              isActive,
              onClick: handleButtonClick,
            }}
          />
          {/* ————— EXPANDING CONTENT ————— */}
          <div className={contentStyle} ref={accordionRef}>
            <ul className={jobListStyle}>
              {jobsSortedByTeamName?.map((job) => {
                return <SearchJobsResultLink job={job} key={job.publicJobId} />;
              })}
            </ul>
          </div>
        </div>
      </Card>
    </div>
  );
}

SearchJobsResultAccordionWrapper.defaultProps = {
  jobList: {},
  title: null,
};
SearchJobsResultAccordionWrapper.propTypes = {
  jobList: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
};
