import React, { useCallback, useEffect, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { graphql, navigate } from "gatsby";
import PropTypes from "prop-types";
import { Configure, InstantSearch } from "react-instantsearch-dom";
import Contain from "~components/atoms/contain/contain";
import SVGBackgroundHeroWrapper from "~components/atoms/svg_background_image/svg_background_image";
import JobSearchHits from "../../components/organisms/search/search_jobs/search_job_hits_wrapper/search_job_hits_wrapper";
import {
  MEDIA,
  STORYBLOK_ENTRY,
  STORYBLOK_ENTRY_ARRAY,
} from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";
import JobListHero from "./components/job_list_hero/job_list_hero";

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);
export default function TemplateListJobs(props) {
  const { data, pageContext, location } = props;
  const { nodes: entryAssets } = data?.assets || [];
  const { pagePath, subNavData, title } = pageContext;

  const [searchState, setSearchState] = useState(location.state || {});

  useEffect(() => {
    navigate(".", { state: {}, replace: true });
  }, []);

  const clearSearchState = useCallback(() => {
    setSearchState({});
  }, []);

  const [roleCount, setRoleCount] = useState(null);

  return (
    <TemplateGlobalEntry
      subNavData={subNavData}
      entryAssets={entryAssets}
      headerColor="blue"
      pagePath={pagePath}
      isListPage
      {...props}
    >
      <InstantSearch
        searchState={searchState}
        searchClient={searchClient}
        onSearchStateChange={setSearchState}
        indexName="careers hub data"
      >
        <Configure hitsPerPage={999} />

        {/* ————— PAGE HERO & SEARCH CONTROLS ————— */}
        <SVGBackgroundHeroWrapper
          bottomPaddingSize="small"
          hasWave
          background="careers_primary"
        >
          <JobListHero {...{ roleCount, searchState, setRoleCount, title }} />
        </SVGBackgroundHeroWrapper>

        {/* ————— SEARCH RESULTS ————— */}
        <Contain>
          <JobSearchHits
            roleCountCallback={setRoleCount}
            clearSearchState={clearSearchState}
          />
        </Contain>
      </InstantSearch>
    </TemplateGlobalEntry>
  );
}

TemplateListJobs.propTypes = {
  data: PropTypes.shape({
    story: STORYBLOK_ENTRY,
    items: STORYBLOK_ENTRY_ARRAY,
    assets: PropTypes.arrayOf(MEDIA),
  }).isRequired,
  pageContext: PropTypes.shape({
    pagePath: PropTypes.string,
    title: PropTypes.string,
    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  location: PropTypes.shape({ state: PropTypes.shape({}) }),
};
TemplateListJobs.defaultProps = {
  location: {},
};

export const query = graphql`
  fragment jobListPageStory on Query {
    story: storyblokEntry(id: { eq: $id }) {
      assets {
        ...asset
      }
      content
      full_slug
      group_id
      alternates {
        full_slug
      }
    }
  }

  query jobListPageQuery($id: String, $langRegex: String) {
    ...jobListPageStory
    ...global
  }
`;
