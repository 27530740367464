export default function getDistinctOffices(jobArray) {
  return Array.from(
    new Set(
      jobArray.map((item) => {
        return item.office;
      })
    )
  )
    .sort()
    .reverse();
}
